<div class="container-input" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <label [class]="labelClass" *ngIf="nameLabelTranslate && !label" [for]="nameInput">
        {{ nameLabelTranslate }} {{nameLabelLiteral}} <span [class]="optionalClass">{{ optionalTranslate }}</span> <img *ngIf="icon" alt="icon-input" class="input-icon" [src]="icon"
            (click)="iconAction()"> </label>
    <label [class]="labelClass" *ngIf="!nameLabelTranslate && label" [for]="nameInput">{{ label }}</label>
    <input #ipt [class]="inputClass" [attr.size]="size" [attr.autocomplete]="autocomplete" [attr.maxlength]="maxlength"
        [attr.minlength]="minlength" [attr.title]="title" [attr.placeholder]="placeholder"
        [ngClass]=" {'error-input': (viewIconErrorInput && !focused && formControl.errors && (formControl.touched || formControl.dirty ) || viewMessageError || errorClass),
            'show-error': viewMessageError,'autocomplete': (matAutocomplete && !formControl.value) || (matAutocomplete && focused) || (optionSelected) }" [attr.type]="typeInput" [attr.name]="nameInput"
        [attr.id]="idInput" [attr.readonly]="readonly" [attr.disabled]="disabled" [attr.aria-label]="ariaLabel"
        [attr.aria-valumin]="min" [attr.aria-valuemax]="max" [attr.aria-required]="ariaRequired"
        [attr.pattern]="pattern" [attr.inputmode]="inputmode" [attr.required]="required"
        [attr.tabindex]="tabindex" (keydown)="onKeyDown($event)" (keypress)="onKeyPress($event)"
        (paste)="onPaste($event)" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
        (input)="onInputChange($event)" fpValidatorRegExp [formControl]="formControl" [regexp]="regexp"
        [matchRegexp]="matchRegexp" [matchOnInput]="matchOnInput" [matchOnBlur]="matchOnBlur" [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin" [matAutocompleteDisabled]="!matAutocomplete">
    <div *ngIf="matAutocomplete && formControl.value" class="delete-search"
        (click)="resetFormControlValue()">
        <img src="../../../../assets/images/input/delete-close.svg" alt="borrar busqueda">
    </div>
    <div *ngIf="detailInput" class="detail-container">
        <ng-container>
            <label >
                {{ detailInput }}
            </label>
        </ng-container>
    </div>
    <div *ngIf="(viewErrorsInput && !focused )|| (viewErrorsInput && viewMessageError)" class="error-container">
        <ng-container *ngFor="let validation of inputValidationMessages">
            <span class="error-message" [ngClass]="{'error-message--current': formControl.hasError(validation.type)}"
                *ngIf="formControl.hasError(validation.type) && (formControl.dirty || formControl.touched)">
                {{ validation.message }}
            </span>
        </ng-container>
    </div>
</div>

<div class="space-inputs" *ngIf="viewSpaceInput">
</div>

<div *ngIf="matAutocomplete && (filteredOptions| async)?.length === 0 && focused" class="not-result-autocomplete">
    <div class="not-text-autocomplete">
        No se encontraron coincidencias
    </div>
</div>

<mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async; let i = index" [value]="option.description"
        (click)="selectCity(option,$event)" (onSelectionChange)=" selectCity(option,$event)" id="{{option.value}}"
        [ngClass]="{'option-bold': i === 0 && formControl.value}">
        {{option.description | titlecase}}
    </mat-option>
</mat-autocomplete>